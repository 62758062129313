import React from "react"
import errorStyles from '../css/error.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Banner from '../components/Banner'
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Error = () => {
  return (
    <Layout>
      <SEO title="Error" />
      <header className={errorStyles.error}>
        <Banner title="Oops Page doesn't exist">
          <AniLink to="/" className="btn-white">Back Home</AniLink>
        </Banner>
      </header>
    </Layout>
  )
}

export default Error
